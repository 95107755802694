import { FC, useCallback, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ALLOWED_IPS, MAINTENANCE_MODE } from '../utils/constants';

interface IMaintenence {
  children: React.ReactNode;
}

const Maintenence: FC<IMaintenence> = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

  const getClientIP = async () => {
    return await fetch('https://api.ipify.org?format=json');
  };

  const getMaintenanceMode = useCallback(async () => {
    let clientIP: string | null = null;
    if (!Boolean(MAINTENANCE_MODE !== 'false' && MAINTENANCE_MODE !== undefined)) return false;
    if (clientIP === null) {
      const response = await getClientIP();
      const data = await response.json();
      clientIP = data.ip;
    }
    // Whitelisted IP addresses
    const allowedIPs = ALLOWED_IPS?.split(',') || [];
    return MAINTENANCE_MODE && !allowedIPs.includes(clientIP);
  }, [MAINTENANCE_MODE]);

  useLayoutEffect(() => {
    (async () => {
      if (await getMaintenanceMode()) {
        setIsMaintenanceMode(true);
      }
    })();
  }, [isMaintenanceMode]);

  return (
    isMaintenanceMode ? <div className="relative flex min-h-screen items-center justify-center overflow-hidden">
      <div className="px-6 py-16 text-center font-semibold before:container before:absolute before:left-1/2 before:-translate-x-1/2 before:rounded-full before:bg-[linear-gradient(180deg,#4361EE_0%,rgba(67,97,238,0)_50.73%)] before:aspect-square before:opacity-10 md:py-20">
        <div className="relative">
          <img
            src='/assets/images/error/maintenence-light.svg'
            alt="maintenence"
            className="mx-auto -mt-10 w-full max-w-xs object-cover md:-mt-20 md:max-w-lg"
          />
          <div className="-mt-8 font-semibold text-black">
            <h2 className="mb-5 text-3xl font-bold text-primary md:text-5xl">Under Maintenance</h2>
            <h4 className="mb-7 text-xl sm:text-2xl">Thank you for visiting us.</h4>
            <p className="text-base">
              We are currently working on making some improvements <br className="hidden sm:block" />
              to give you better user experience. <br />
              <br />
              Please visit us again shortly.
            </p>
            <p className='mt-4'>Tentitive comeback time <strong className='text-primary underline'>{t('date', {
              val: new Date(MAINTENANCE_MODE),
              formatParams: {
                val: { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short' },
              },
            })}</strong></p>
          </div>
        </div>
      </div>
    </div> : children
  );
};

export default Maintenence;
