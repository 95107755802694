import { wrapCreateBrowserRouterV6 } from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';
import BlankLayout from '../components/Layouts/BlankLayout';
import DefaultLayout from '../components/Layouts/DefaultLayout';
import Maintenance from '../pages/Maintenance';
import { lazyWithRetry } from '../utils/lazyWithRetry';
import { AuthRequired } from './AuthRequired';
import { routes } from './routes';
const ClubLandingPage = lazyWithRetry(() => import('../components/ClubMicrosite/LandingPage'));

const finalRoutes = routes.map((route) => {
    const subdomain = window.location.hostname.split('.')[0];

    return {
        ...route,
        element:
            <Maintenance>{subdomain && subdomain !== "targetscore" && subdomain !== "target-score" && subdomain !== "www" && subdomain !== "localhost"
                ? <BlankLayout><ClubLandingPage clubDomain={subdomain} /></BlankLayout>
                : route.layout === 'blank' ? (
                    <BlankLayout>{route.element}</BlankLayout>
                ) : route.protected ? (
                    <AuthRequired>
                        <DefaultLayout>{route.element}</DefaultLayout>
                    </AuthRequired>
                ) : (
                    <DefaultLayout>{route.element}</DefaultLayout>
                )}</Maintenance>,
    };
});

const sentryCreateBrowserRouter = wrapCreateBrowserRouterV6(createBrowserRouter);

const router = sentryCreateBrowserRouter(finalRoutes);

export default router;
export * from './Modal';

