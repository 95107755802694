import { nanoid } from 'nanoid';
import { IMatch } from '../db';
import { ISquaddings } from '../db/Couch/squadding';
import { ListTable } from '../pages/ListParticipants';

export const formatDate = (date: any) => {
    if (date) {
        const dt = new Date(date);
        const month = dt.getMonth() + 1 < 10 ? '0' + (dt.getMonth() + 1) : dt.getMonth() + 1;
        const day = dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate();
        return day + '/' + month + '/' + dt.getFullYear();
    }
    return '';
};

export const capitalize = (text: any) => {
    return text
        .replace('_', ' ')
        .replace('-', ' ')
        .toLowerCase()
        .split(' ')
        .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
};

export const exportJsonParticipants = (data: Array<ListTable>) => {
    const result = data.map((each) => {
        return { id: each.id, competitorNumber: each.competitorNumber, fullname: each.name, division: each.division };
    });
    return JSON.stringify(result);
};

export const getShortPositionName = (name: string) => {
    switch (true) {
        case name === 'Right':
            return 'R';
        case name === 'Left':
            return 'L';
        case name === 'Middle':
            return 'M';
        default:
            return name;
    }
};

export const exportJsonAll = (data: Array<ISquaddings>) => {
    const merged = data.flatMap((each) => exportJson(each));
    return merged;
};

export const exportJson = (data: ISquaddings) => {
    const mergedSquadding = data.squaddings.flat().reduce(
        (acc, curr) => {
            if (acc[0].target === 0) {
                curr.target.map((tar, i) => {
                    acc[i] = {
                        id: tar.partiId,
                        competitorNumber: tar.competitorNumber,
                        fullname: tar.fullname,
                        division: curr.division,
                        matchId: data.matchId,
                        matchName: data.matchName.split(', ')[0],
                        target: tar.target,
                        position: getShortPositionName(tar.position),
                        relay: tar.relay,
                    };
                });
            } else {
                const lastLength = acc.length;
                curr.target.map((tar, i) => {
                    acc[lastLength + i] = {
                        id: tar.partiId,
                        competitorNumber: tar.competitorNumber,
                        fullname: tar.fullname,
                        division: curr.division,
                        matchId: data.matchId,
                        matchName: data.matchName.split(', ')[0],
                        target: tar.target,
                        position: getShortPositionName(tar.position),
                        relay: tar.relay,
                    };
                });
            }
            return acc;
        },
        [{ id: '', competitorNumber: 0, fullname: '', division: '', matchId: '', matchName: '', target: 0, position: '', relay: 0 }]
    );
    return mergedSquadding;
};

export const exportTable = (type: any, col: Array<string>, rowData: any, filename: string) => {
    let columns = col;
    let records: any = rowData;

    // hide country or/and class if all data is same for results
    const hideCountry = [...new Set(rowData.map((row: any) => row.country))].length === 1;
    const hideClassification = [...new Set(rowData.map((row: any) => row.class))].length === 1;

    columns = hideCountry ? columns.filter((each) => each !== 'country') : columns;
    columns = hideClassification ? columns.filter((each) => each !== 'class') : columns;

    let newVariable: any;
    newVariable = window.navigator;

    if (type === 'csv') {
        let coldelimiter = ',';
        let linedelimiter = '\n';
        let result = columns
            .map((d: any) => {
                return capitalize(d);
            })
            .join(coldelimiter);
        result += linedelimiter;
        // eslint-disable-next-line array-callback-return
        records.map((item: any) => {
            // eslint-disable-next-line array-callback-return
            columns.map((d: any, index: any) => {
                if (index > 0) {
                    result += coldelimiter;
                }
                let val = item[d] ? (typeof item[d] === 'string' ? `"${item[d]}"` : typeof item[d] === 'boolean' ? (Boolean(item[d]) ? 'Yes' : 'No') : item[d]) : '';
                result += val;
            });
            result += linedelimiter;
        });

        if (result == null) return;
        if (!result.match(/^data:text\/csv/i) && !newVariable.msSaveOrOpenBlob) {
            var data = 'data:application/csv;charset=utf-8,' + encodeURIComponent(result);
            var link = document.createElement('a');
            link.setAttribute('href', data);
            link.setAttribute('download', filename + '.csv');
            link.click();
        } else {
            var blob = new Blob([result]);
            if (newVariable.msSaveOrOpenBlob) {
                newVariable.msSaveBlob(blob, filename + '.csv');
            }
        }
    } else if (type === 'print') {
        var rowhtml = '<p>' + filename + '</p>';
        rowhtml +=
            '<table style="width: 100%; " cellpadding="0" cellcpacing="0"><thead><tr style="color: #515365; background: #eff5ff; -webkit-print-color-adjust: exact; print-color-adjust: exact; "> ';
        // eslint-disable-next-line array-callback-return
        columns.map((d: any) => {
            rowhtml += '<th>' + capitalize(d) + '</th>';
        });
        rowhtml += '</tr></thead>';
        rowhtml += '<tbody>';

        // eslint-disable-next-line array-callback-return
        records.map((item: any) => {
            rowhtml += '<tr>';
            // eslint-disable-next-line array-callback-return
            columns.map((d: any) => {
                let val = item[d] ? item[d] : '';
                rowhtml += '<td>' + val + '</td>';
            });
            rowhtml += '</tr>';
        });
        rowhtml +=
            '<style>body {font-family:Arial; color:#495057;}p{text-align:center;font-size:18px;font-weight:bold;margin:15px;}table{ border-collapse: collapse; border-spacing: 0; }th,td{font-size:12px;text-align:left;padding: 4px;}th{padding:8px 4px;}tr:nth-child(2n-1){background:#f7f7f7; }</style>';
        rowhtml += '</tbody></table>';
        var winPrint: any = window.open('', '', 'left=0,top=0,width=1000,height=600,toolbar=0,scrollbars=0,status=0');
        winPrint.document.write('<title>Print</title>' + rowhtml);
        winPrint.document.close();
        winPrint.focus();
        winPrint.print();
    }
};

export function createCookie(name: string, value: string, days: number) {
    let expires;
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    } else {
        expires = '';
    }
    document.cookie = name + '=' + value + expires + '; path=/';
}

export const getCookie = (name: string) => document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';

export const createID = (value: string, appendUID: number = 10) => {
    const nanoID = appendUID ? `_${nanoid(appendUID)}` : null;
    return `${value.trim().replace(/[^a-zA-Z0-9]/g, '_').replace(/^_+|_+$/g, '')}${nanoID || ''}`;
};

export const printSchedule = (matches: Array<IMatch> | undefined, name?: string) => {
    const cols = ['matchDate', 'matchTime', 'type', 'matchName', 'distance', 'range', 'shots', 'sighters', 'targetFace', 'warmup'];
    const filename = `Schedule ${name ? name : ''}`;

    var rowhtml = '<p>' + filename + '</p>';
    rowhtml += '<table style="width: 100%; " cellpadding="0" cellcpacing="0"><thead><tr style="color: #515365; background: #eff5ff; -webkit-print-color-adjust: exact; print-color-adjust: exact; "> ';
    // eslint-disable-next-line array-callback-return
    cols.map((d: any) => {
        rowhtml += '<th>' + capitalize(d.split(/(?=[A-Z])/).join(' ')) + '</th>';
    });
    rowhtml += '</tr></thead>';
    rowhtml += '<tbody>';

    if (matches && matches.length > 0) {
        // eslint-disable-next-line array-callback-return
        matches
            .sort((a, b) => Date.parse(a.matchDate.toString()) - Date.parse(b.matchDate.toString()))
            .map((match: IMatch, i) => {
                rowhtml += '<tr>';
                // eslint-disable-next-line array-callback-return
                cols.map((d: string) => {
                    let val = colPrintData(match, d, i);
                    rowhtml += '<td>' + val + '</td>';
                });
                rowhtml += '</tr>';
            });
        rowhtml +=
            '<style>body {font-family:Arial; color:#495057;}p{text-align:center;font-size:18px;font-weight:bold;margin:15px;}table{ border-collapse: collapse; border-spacing: 0; }th,td{font-size:12px;text-align:left;padding: 4px;}th{padding:8px 4px;}tr:nth-child(2n-1){background:#f7f7f7; }</style>';
        rowhtml += '</tbody></table>';
        var winPrint: any = window.open('', '', 'left=0,top=0,width=1000,height=600,toolbar=0,scrollbars=0,status=0');
        winPrint.document.write('<title>Print</title>' + rowhtml);
        winPrint.document.close();
        winPrint.focus();
        winPrint.print();
    }
};

export const colPrintData = (match: any, col: string, index: number, squad?: boolean) => {
    switch (true) {
        case col === 'type':
            return match['type'] === 'I' ? 'Individual' : 'Team';
        case col === 'matchDate':
            return new Date(match['matchDate']).toLocaleDateString('en-GB', { dateStyle: 'full' });
        case col === 'matchTime' && !squad:
            return new Date(match['matchDate']).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        case col === 'matchTime' && squad:
            return new Date(match['matchTime']).toLocaleString([], { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit' });
        case col === 'warmup':
            return match['warmup'] ? 'Yes' : '';
        default:
            return match[col] ? match[col] : '';
    }
};

export const getRandomColor = () => {
    const colorArr = ['primary', 'success', 'danger', 'dark', 'warning', 'info', 'secondary'];
    return colorArr[Math.floor(Math.random() * colorArr.length)];
};

export const MAX_FILE_SIZE = 256000; // 200KB
export const validFileExtensions: any = { image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'] };
export const validImportExtensions: any = { shotMarker: ['txt', 'json'] };

export function isValidFileType(fileName: any, fileType: string, extenstions: any) {
    return fileName === undefined ? true : extenstions[fileType].indexOf(fileName.split('.').pop()) > -1;
}

export function arrayMove(arr: Array<any>, fromIndex: number, toIndex: number, setArr: Function) {
    const copy = [...arr];
    const element = copy[fromIndex];
    copy.splice(fromIndex, 1);
    copy.splice(toIndex, 0, element);
    setArr(copy);
}

export const groupBy = (arr: Array<any>, property: string) => {
    return arr.reduce((acc, cur) => {
        acc[cur[property]] = [...(acc[cur[property]] || []), cur];
        return acc;
    }, {});
};

export const filterObject = (obj: any, predicate: any) => Object.fromEntries(Object.entries(obj).filter(predicate));

export function arraysEqual(a: string[] | undefined, b: string[]) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}