import * as React from 'react';
import { SITEWIDE_NOTICE } from "../../utils/constants";
// Format: bg-danger:Site is being upgraded:text-white

interface INotice {

}

const sitewide_notice = SITEWIDE_NOTICE || ""; // bgClass:Message

export const Notice: React.FC<INotice> = () => {
    const notice = sitewide_notice.split(":");
    const bgClass = notice[0];
    const body = notice[1];
    const textClass = notice[2] || "text-white";
    return Boolean(body) ? <div className={`d-block px-3 py-2 text-center font-bold hide-for-print ${bgClass}`}>
    <span className={`${textClass} text-decoration-none`}>{body}</span>
  </div> : null;
}