import { FC } from 'react';


export const PageLoader: FC = () => {
    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="text-lg font-semibold text-gray-700">
                Loading...
            </div>
        </div>
    );
}