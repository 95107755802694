import * as Sentry from '@sentry/react';
import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom/client';

// Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// Tailwind css
import './tailwind.css';

// i18n (needs to be bundled)
import './i18n';

// Router
import { RouterProvider, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import router from './router/index';

// Redux
import { Provider } from 'react-redux';
import store from './store/index';
import { ENV, POSTHOG_HOST, POSTHOG_KEY } from "./utils/constants";

// Post Hog for Replays
import { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from "posthog-js/react";
import { PageLoader } from './components/Loaders';

const options: Partial<PostHogConfig> = {
    api_host: POSTHOG_HOST,
    autocapture: false,
    session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
            password: true,
            tel: true,
        }
    },
    loaded: (ph) => {
        if (ENV == 'development') {
            ph.opt_out_capturing();
            ph.set_config({ disable_session_recording: true });
        }
    },
}

Sentry.init({
    dsn: 'https://a0f3e74916cbeb6234ba1ce67b15749e@o4506357104836608.ingest.sentry.io/4506357106802688',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
        }),
        Sentry.feedbackIntegration({
            colorScheme: "light",
            showBranding: false
        }),
        Sentry.replayIntegration({
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
            ignore: ['.ignore-me'],
            networkDetailAllowUrls: ["https://db.targetscore.com", window.location.origin],
        })
    ],
    tracePropagationTargets: [/^https:\/\/.*\.targetscore\.com\//],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.0, // Disable session replay by default
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: ENV !== 'development',
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <PostHogProvider apiKey={POSTHOG_KEY} options={options}>
            <Suspense fallback={<PageLoader />}>
                <Provider store={store}>
                    <RouterProvider router={router} />
                </Provider>
            </Suspense>
        </PostHogProvider>
    </React.StrictMode>
);
