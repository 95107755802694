import { DateSelectArg } from '@fullcalendar/core';
import { useMaskito } from '@maskito/react';
import 'flatpickr/dist/flatpickr.css';
import { Field, FieldProps, Formik, FormikHelpers, FormikTouched, setNestedObjectValues } from 'formik';
import { FC, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import Flatpickr from 'react-flatpickr';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { Form, useNavigate } from 'react-router-dom';
import Select from "react-select";
import * as Yup from 'yup';
import { IconPlus, showAlert } from '../components';
import { EVARIANT, MessageBanner } from "../components/Alerts/MessageBanner";
import { toast } from "../components/Alerts/Toast";
import { useErrorAlerts } from "../components/Alerts/useErrorAlerts";
import { SelectField } from '../components/Forms/SelectField';
import { IClub, IClubDisciplines, IEvent, ORules, eventDB, findClubsByOwner } from '../db';
import { createPriceItem, updatePriceItem } from '../db/Clients/stripe';
import { IOrderItem, ITaxItem, createOrderItem, getOrderItems, getTaxItems } from '../db/Clients/teamupmate';
import { EDIVISIONS, ODivisions, getDivisionByRules } from '../db/Models/divisions';
import useGateways from '../hooks/useGateways';
import { AppRoutes } from '../router';
import { IRootState } from '../store';
import { setClub } from '../store/clubReducer';
import { setEvent } from '../store/eventReducer';
import { setPageTitle } from '../store/themeConfigSlice';
import { arraysEqual, createID } from '../utils/common-utils';
import { currencyMask } from '../utils/mask';
import { urlExp } from '../utils/regex';

interface Props {
    edit?: boolean;
    event?: IEvent;
    closeModal?: Function;
    datesSelected?: DateSelectArg;
}

const CreateEvent: FC<Props> = ({ edit, event, closeModal, datesSelected }) => {
    const { t } = useTranslation();
    const { gateways, getGateways } = useGateways();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const oops = useErrorAlerts();
    const maskedInputRef = useMaskito({ options: currencyMask('$') });
    const [isNoChange, setNoChange] = useState(false);
    const [taxItems, setTaxItems] = useState<ITaxItem[]>([]);
    const [orderItems, setOrderItems] = useState<IOrderItem[]>([]);

    const {
        user,
        club: { selectedClub },
    } = useSelector((state: IRootState) => state);

    useEffect(() => {
        dispatch(setPageTitle(t('CREATE_EVENT')));
    });

    const [clubs, setClubs] = useState<Array<IClub>>([]);

    useEffect(() => {
        const clubs = async () => {
            if (user.user.metadata?.id) {
                const data = await findClubsByOwner(user.user.metadata.id);
                setClubs(data);
            }
        };
        clubs().catch((e) => console.error(e));
    }, []);

    useEffect(() => {
        if (clubs.length > 0 && edit && event) {
            const selectedClub = clubs.find((club) => club._id === event.club);
            selectedClub && dispatch(setClub(selectedClub));
        }
    }, [edit, clubs, event]);

    const SubmittedForm = Yup.object({
        club: Yup.string().required(t('SELECT_ORGANIZING_CLUB')).default(''),
        name: Yup.string().required(t('FILL_EVENT_NAME')).default(''),
        // unit: Yup.mixed<RangeUnit>().oneOf([RangeUnit.YARDS, RangeUnit.METERS]).required('Please select the range unit.').default(RangeUnit.YARDS),
        rules: Yup.string().required(t('SELECT_RULES')).default(''),
        dates: Yup.mixed().required(t('SELECT_START_END_DATE')).default([]),
        website: Yup.string().matches(urlExp, { message: t('CORRECT_URL'), excludeEmptyString: true }).default(''),
        overview: Yup.string().default(''),
        howToEnter: Yup.string().default(''),
        emailMessage: Yup.string().default(''),
        email: Yup.string().required(t('FILL_SUPPORT_EMAIL')).default(''),
        fee: Yup.string().required(t('FILL_FEE')).default(''),
        entryDates: Yup.mixed(),
        notify: Yup.string().oneOf(['contactEmail', 'managers', 'other'], t('CHOOSE_NOTIFICATION_EMAIL')).default('contactEmail'),
        otherEmail: Yup.string().default(''),
        featParti: Yup.bool().default(true),
        featScores: Yup.bool().default(true),
        featMatches: Yup.bool().default(true),
        divisions: Yup.array().min(1).of(Yup.string().required()).required(),
        ageAgg: Yup.bool().default(true),
        genderAgg: Yup.bool().default(true),
        classAgg: Yup.bool().default(true),
        hidden: Yup.bool().default(true),
        agree: Yup.bool().oneOf([true], t('YOU_MUST_AGREE_TO_TERMS')).default(false),
        gateway: Yup.array().of(Yup.string()).default([]),
        priceItem: Yup.string().default(''),
        limits: Yup.number().nullable().default(null),
        waitlist: Yup.bool().default(false),
        donation: Yup.bool().default(true),
        tax: Yup.string().default(""),
        createOrder: Yup.bool().default(false),
        type: Yup.string().default(''),
        tumItem: Yup.mixed().default(null),
        nonMemberNotification: Yup.bool().default(false),
    });

    type IEvent = Yup.InferType<typeof SubmittedForm>;

    const handleSubmit = async (values: IEvent, actions: FormikHelpers<IEvent>) => {
        try {
            const id = createID(values.name, 6);
            const owner = user.user.metadata?.id;
            const latest = edit ? await eventDB().get((values as any)._id) : null;

            if (owner) {
                const docValues = edit
                    ? {
                        ...values,
                        _rev: latest!._rev,
                    }
                    : {
                        ...values,
                        _id: id,
                        owner,
                        clubName: clubs.find((club) => club._id === values.club)?.clubName,
                        groups: [],
                        matches: [],
                        specialCat: [],
                        aggs: [],
                        doc_type: 'event',
                        priceItem: '',
                    };
                const price = parseInt(values.fee.replace(/\D/g, '') || '0');
                const currency = selectedClub?.currency || 'cad';
                const stripeGateway = gateways.filter((gateway) => values.gateway.includes(gateway._id)).find((gateway) => gateway.stripeConfigured);
                if (!currency) {
                    toast({ title: t("SET_CURRENCY_IN_CLUB_SETTINGS"), timer: 6000 });
                }
                // Create price item for Stripe
                if (!edit && price && currency && stripeGateway) {
                    const priceItem = await createPriceItem(price, currency, values.name, stripeGateway.stripeAccountId!);
                    if (priceItem.id) {
                        toast({ title: t("NEW_PRICE_ITEM_CREATED"), timer: 3000 });
                        docValues.priceItem = priceItem.id;
                    } else {
                        throw new Error(t("PRICE_ITEM_CREATION_FAILED"));
                    }
                }
                // Update price item for existing if price is different
                if (edit && currency && price && stripeGateway && latest && (latest.fee !== values.fee || !arraysEqual(latest.gateway, values.gateway))) {
                    if (values.priceItem) {
                        // Deactivate old price item
                        const deactivated = await updatePriceItem(values.priceItem, false, values.name, stripeGateway.stripeAccountId!);
                        if (deactivated) {
                            toast({ title: t("OLD_PRICE_ITEM_DEACTIVATED"), timer: 3000 });
                        }
                    }
                    if (price) {
                        // Create New Price Item
                        const priceItem = await createPriceItem(price, currency, values.name, stripeGateway.stripeAccountId!);
                        if (priceItem.id) {
                            toast({ title: t("NEW_PRICE_ITEM_CREATED"), timer: 3000 });
                            docValues.priceItem = priceItem.id;
                        } else {
                            throw new Error(t("PRICE_ITEM_CREATION_FAILED"));
                        }
                    } else {
                        toast({ title: t("PAYMENT_GATEWAY_WILL_NOT_PRESENTED"), timer: 3000 });
                    }
                }
                // Create Item in Team Up Mate if integrated, and no existing item is selected
                if (selectedClub.tumKey && values.createOrder && !values.tumItem) {
                    // Create Item in Team Up Mate
                    if (!values.tax) {
                        showAlert({ icon: 'error', titleText: t("TAX_NOT_SELECTED"), text: t("SELECT_TAX_ITEM") });
                        return;
                    }
                    const response = await createOrderItem(selectedClub._id, {
                        itemName: values.name,
                        price: values.fee,
                        taxId: values.tax,
                    });
                    if (response.success) {
                        (docValues as any).tumItem = response.data;
                        toast({ title: t("ITEM_CREATED_TUM"), timer: 3000 });
                    } else {
                        throw new Error(t("FAILED_CREATING_ITEM_TUM"));
                    }
                }

                const newEvent = await eventDB().put(docValues as any);
                const selectedEvent = await eventDB().get(newEvent.id);
                dispatch(setEvent(selectedEvent));
                if (newEvent.id)
                    edit && closeModal
                        ? (closeModal(false), toast({ title: t('EVENT_UPDATED'), timer: 6000 }), navigate(AppRoutes.EVENT_DETAILS.replace(':event_id', newEvent.id)))
                        : navigate(values.featMatches ? AppRoutes.MATCHES : values.featParti ? AppRoutes.LIST_PARTICIPANTS : AppRoutes.CLUB_EVENTS);
            }
        } catch (e) {
            oops(e, 'create_edit_event');
        }
    };

    const [isMoreOptions, setMoreOptions] = useState<boolean>(false);

    useEffect(() => {
        if (selectedClub?._id) {
            getGateways(selectedClub);
        }
        if (selectedClub.tumKey) {
            getTaxItems(selectedClub._id).then((response) => {
                setTaxItems(response.data);
            });
            // TODO: Make this call only one create order item selection
            getOrderItems(selectedClub._id).then((response) => {
                setOrderItems(response.data);
            });
        }
    }, [selectedClub]);

    return (
        <div className="panel">
            {!edit && (
                <div className="mb-5 flex items-center justify-between">
                    <h1 className="text-xl font-semibold dark:text-white-light text-primary">{t("CREATE_EVENT")}</h1>
                </div>
            )}
            {
                gateways.length === 0 && <div className="flex items-center border p-3.5 mb-5 rounded border-warning">
                    <span className="pr-2">
                        <strong className="mr-2 text-warning">{t("GATEWAY_UNAVAILABLE")}</strong>{t("PLEASE_CREATE_GATEWAY")}
                    </span>
                </div>
            }
            <div className="mb-5">
                <Formik
                    initialValues={{
                        club: selectedClub?._id || '',
                        name: '',
                        // unit: RangeUnit.YARDS,
                        rules: '',
                        dates: '',
                        website: '',
                        overview: '',
                        howToEnter: '',
                        emailMessage: '',
                        email: '',
                        fee: '',
                        entryDates: '',
                        notify: 'contactEmail',
                        otherEmail: '',
                        featParti: true,
                        featScores: true,
                        featMatches: true,
                        divisions: [EDIVISIONS.TR, EDIVISIONS.FOPEN, EDIVISIONS.FTR],
                        ageAgg: true,
                        genderAgg: true,
                        classAgg: true,
                        hidden: false,
                        agree: false,
                        gateway: [],
                        priceItem: '',
                        limits: null,
                        waitlist: false,
                        donation: true,
                        tax: "",
                        createOrder: false,
                        type: '',
                        tumItem: null,
                        nonMemberNotification: false,
                    }}
                    validationSchema={SubmittedForm}
                    onSubmit={(values: IEvent, actions) => {
                        handleSubmit(values, actions);
                    }}
                >
                    {({ errors, touched, values, setFieldValue, setFieldTouched, handleSubmit, setTouched, setValues }) => {
                        useEffect(() => {
                            edit && event && setValues(event as any);
                        }, [event]);
                        useEffect(() => {
                            if (values.club && !edit) {
                                const selectedClub = clubs.find((item) => item._id === values.club);
                                selectedClub && dispatch(setClub(selectedClub));
                                setFieldValue('email', selectedClub?.email || user.user.name || "");
                                setFieldTouched('email');
                                selectedClub?.clubDivisions && setFieldValue('divisions', selectedClub.clubDivisions.map((div) => div.divKey));
                            }
                        }, [values.club]);
                        useEffect(() => {
                            if (values.rules) {
                                setFieldValue('divisions', getDivisionByRules(values.rules)?.map((div) => div.value));
                                setTouched({ ...touched, ['divisions']: true });
                            }
                        }, [values.rules]);
                        const setDates = (dates: Date[]) => {
                            setFieldValue('dates', dates.map(each => each.toLocaleDateString("en-CA"))), setTouched({ ...touched, ['dates']: true });
                        };
                        useEffect(() => {
                            if (gateways.length > 0 && !edit) {
                                setFieldValue('gateway', gateways.map(each => each._id));
                                setTouched({ ...touched, ['gateway']: true });
                            }
                        }, [gateways]);
                        useEffect(() => {
                            if (datesSelected && datesSelected.startStr && datesSelected.endStr) {
                                setFieldValue('dates', [datesSelected.startStr, datesSelected.endStr]);
                                setTouched({ ...touched, ['dates']: true });
                            }
                        }, [datesSelected]);
                        // console.log(errors);
                        // console.log(values);
                        return (
                            <Form className="space-y-5">
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                                    {/* Club */}
                                    {!edit && (
                                        <div className={errors.club && touched.club ? 'has-error' : ''}>
                                            <label htmlFor="club">{t("SELECT_CLUB")} </label>
                                            <Field
                                                name="club"
                                                id="club"
                                                component={SelectField}
                                                placeholder={t("SELECT_CLUB")}
                                                hasError={errors.club}
                                                options={clubs.map((club: IClub) => ({ value: club._id, label: club.clubName }))}
                                            />
                                            {errors.club && touched.club && <div className=" text-danger mt-1">{errors.club}</div>}
                                        </div>
                                    )}

                                    {/* Event Discipline */}
                                    {selectedClub.clubDisciplines && <div className={errors.club && touched.club ? 'has-error' : ''}>
                                        <label htmlFor="type">{t("DISCIPLINE")} </label>
                                        <Field
                                            name="type"
                                            id="type"
                                            component={SelectField}
                                            placeholder={t("DISCIPLINE")}
                                            hasError={errors.type}
                                            options={selectedClub.clubDisciplines.map((club: IClubDisciplines) => ({ value: club.name, label: club.name }))}
                                        />
                                        {errors.type && touched.type && <div className=" text-danger mt-1">{errors.type}</div>}
                                    </div>}

                                    {/* Event Name */}
                                    <div className={errors.name && touched.name ? 'has-error' : ''}>
                                        <label htmlFor="name">{t("EVENT_NAME")}</label>
                                        <Field name="name" type="text" id="name" placeholder={t("ENTER_EVENT_NAME")} className="form-input" />

                                        {errors.name && touched.name && <div className="text-danger mt-1">{errors.name}</div>}
                                    </div>

                                    {/* Unit */}
                                    {/* <div className={errors.unit && touched.unit ? 'has-error' : ''}>
                                        <label htmlFor="unit">Range Unit of Measurement </label>
                                        <div className="flex gap-4" aria-labelledby="unit" role="radiogroup">
                                            <label className="inline-flex">
                                                <Field type="radio" name="unit" value={RangeUnit.YARDS} className="form-radio rounded-none" />
                                                <span>Yards</span>
                                            </label>
                                            <label className="inline-flex">
                                                <Field type="radio" name="unit" value={RangeUnit.METERS} className="form-radio rounded-none" />
                                                <span>Meters</span>
                                            </label>
                                        </div>
                                        {errors.unit && touched.unit && <div className=" text-danger mt-1">{errors.unit}</div>}
                                    </div> */}
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                                    {/* Rules */}
                                    <div className={errors.rules && touched.rules ? 'has-error' : ''}>
                                        <label htmlFor="rules">Rules </label>
                                        <Field as="select" name="rules" id="rules" className="form-select">
                                            <option key="NA" value="" hidden>
                                                {t("OPEN_TO_SELECT_MENU")}
                                            </option>
                                            {ORules.map((rule, i) => (
                                                <option key={i} value={rule.code}>
                                                    {rule.name}
                                                </option>
                                            ))}
                                        </Field>
                                        {errors.rules && touched.rules && <div className=" text-danger mt-1">{errors.rules}</div>}
                                    </div>

                                    {/* Dates */}
                                    <div className={errors.dates && touched.dates ? 'has-error' : ''}>
                                        <label htmlFor="dates">{t("DATES")} </label>
                                        <Field id="dates" name="dates">
                                            {({ field }: FieldProps) => (
                                                <Flatpickr
                                                    options={{
                                                        mode: 'range',
                                                        dateFormat: 'Y-m-d',
                                                        position: 'auto left',
                                                    }}
                                                    id="dates"
                                                    className="form-input"
                                                    value={field.value}
                                                    onChange={(dates) => setDates(dates)}
                                                />
                                            )}
                                        </Field>
                                        {errors.dates && touched.dates && <div className="text-danger mt-1">{errors.dates.toString()}</div>}
                                        <span className="text-black dark:text-gray-400 text-xs">{t('DATE_HELPER')}</span>
                                    </div>

                                    {/* Website */}
                                    <div className={errors.website && touched.website ? 'has-error' : ''}>
                                        <label htmlFor="website">{t("WEBSITE")} </label>
                                        <Field name="website" type="text" id="website" placeholder="www.example.com/events/xyz" className="form-input" />

                                        {errors.website && touched.website && <div className="text-danger mt-1">{errors.website}</div>}
                                    </div>
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                                    {/* Overview */}
                                    <div className={errors.overview && touched.overview ? 'has-error' : ''}>
                                        <label htmlFor="overview">{t("OVERVIEW")}</label>
                                        <Field id="overview" name="overview">
                                            {({ field }: any) => (
                                                <ReactQuill theme="snow" value={field.value} onChange={(e) => setFieldValue(field.name, e)} onFocus={() => setFieldTouched(field.name, true)} />
                                            )}
                                        </Field>
                                    </div>

                                    {/* How To Enter */}
                                    <div className={errors.howToEnter && touched.howToEnter ? 'has-error' : ''}>
                                        <label htmlFor="howToEnter">{t("HOW_TO_ENTER")}</label>
                                        <Field id="howToEnter" name="howToEnter">
                                            {({ field }: any) => (
                                                <ReactQuill theme="snow" value={field.value} onChange={(e) => setFieldValue(field.name, e)} onFocus={() => setFieldTouched(field.name, true)} />
                                            )}
                                        </Field>
                                    </div>

                                    {/* Welcome Email */}
                                    <div className={errors.emailMessage && touched.emailMessage ? 'has-error' : ''}>
                                        <label htmlFor="emailMessage">{t("WELCOME_EMAIL_BODY")}</label>
                                        <Field id="emailMessage" name="emailMessage">
                                            {({ field }: any) => (
                                                <ReactQuill theme="snow" value={field.value} onChange={(e) => setFieldValue(field.name, e)} onFocus={() => setFieldTouched(field.name, true)} />
                                            )}
                                        </Field>
                                    </div>
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                                    {/* Email */}
                                    <div className={errors.email && touched.email ? 'has-error' : ''}>
                                        <label htmlFor="emailLabel">{t("CONTACT_EMAIL")}</label>
                                        <fieldset aria-labelledby="emailLabel">
                                            <label htmlFor="email" className="text-white-dark">
                                                example@example.com
                                            </label>
                                            <Field name="email" type="email" id="email" className="form-input" />
                                        </fieldset>
                                        {errors.email && touched.email && <div className="text-danger mt-1">{errors.email}</div>}
                                    </div>

                                    {/* Fee */}
                                    <div className={errors.fee && touched.fee ? 'has-error' : ''}>
                                        <label htmlFor="fee">{t("ENTRY_FEE")} <span aria-hidden>*</span></label>
                                        <fieldset>
                                            <label htmlFor="fee" className="text-white-dark">
                                                $999.99 or $0.00 for free events.
                                            </label>
                                            <input id="fee" type="text" value={values.fee} onBlur={() => setFieldTouched('fee')} onInput={(e) => setFieldValue('fee', e.currentTarget.value)} ref={maskedInputRef} className="form-input" />
                                        </fieldset>
                                    </div>

                                    {/* Entry Dates */}
                                    <div className={errors.dates && touched.dates ? 'has-error' : ''}>
                                        <label htmlFor="entryDates">{t("ENTRY_DATES")}</label>
                                        <fieldset>
                                            <label htmlFor="entryDates" className="text-white-dark">
                                                {t("ENTRY_DATES_DESC")}
                                            </label>
                                            <Field id="entryDates" name="entryDates">
                                                {({ field }: any) => (
                                                    <Flatpickr
                                                        options={{
                                                            defaultHour: 8,
                                                            defaultMinute: 0,
                                                            enableTime: true,
                                                            dateFormat: 'Y-m-d H:i',
                                                            time_24hr: true,
                                                            mode: 'range',
                                                            position: 'auto left',
                                                            maxDate: values.dates?.[1],
                                                        }}
                                                        value={field.value}
                                                        id="entryDates"
                                                        className="form-input"
                                                        onChange={(dates) => setFieldValue('entryDates', dates)}
                                                    />
                                                )}
                                            </Field>
                                        </fieldset>
                                    </div>
                                </div>
                                <div>
                                    {/* Entry Notification Emails */}
                                    <div className={`col-span-3 ${errors.notify && touched.notify ? 'has-error' : ''}`}>
                                        <label htmlFor="notificationLabel" className="font-sans text-primary mb-3 text-base">
                                            {t("ENTRY_REQUEST_NOTIFICATION")}
                                        </label>
                                        <div className="mb-3 flex flex-col gap-1">
                                            <label className="inline-flex">
                                                <Field type="radio" name="notify" value="contactEmail" className="form-checkbox text-info rounded-full peer" />
                                                <span className="peer-checked:text-info">{t("NOTIFICATION_CONTACT")}</span>
                                            </label>
                                            {/* <label className="inline-flex">
                                                <Field type="radio" name="notify" value="managers" className="form-checkbox text-info rounded-full peer" />
                                                <span className="peer-checked:text-info">Send notification to all club managers.</span>
                                            </label> */}
                                            <label className="inline-flex">
                                                <Field type="radio" name="notify" value="other" className="form-checkbox text-info rounded-full peer" />
                                                <span className="peer-checked:text-info">{t("OTHER_EMAIL_ADDRESS")}</span>
                                            </label>
                                        </div>
                                        {values.notify === 'other' && (
                                            <fieldset aria-labelledby="notificationLabel">
                                                <label htmlFor="notify" className="text-white-dark">
                                                    john@example.com, doe@example.com
                                                </label>
                                                <Field name="otherEmail" type="email" id="notify" className="form-input" />
                                            </fieldset>
                                        )}
                                        {errors.notify && touched.notify && <div className="text-danger mt-1">{errors.notify}</div>}
                                    </div>
                                </div>

                                <button
                                    type="button"
                                    className="w-full flex items-center text-primary"
                                    onClick={() => setMoreOptions(!isMoreOptions)}
                                >
                                    <div className="me-1">
                                        <IconPlus outline={false} className='text-primary' />
                                    </div>
                                    <span className='text-lg font-semibold whitespace-nowrap pe-3'>{isMoreOptions ? t('LESS_OPTIONS') : t('MORE_OPTIONS')}</span>
                                    <hr className='w-full' />
                                </button>
                                <AnimateHeight duration={300} height={isMoreOptions ? 'auto' : 0}>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                                        <div className="">
                                            <h2 className="font-semibold text-primary mb-3 text-base">{t("SELECT_FEATURES")}</h2>
                                            {/* Features */}
                                            <div className="flex gap-3">
                                                <div className={errors.featParti && touched.featParti ? 'has-error' : ''}>
                                                    <div className="flex">
                                                        <Field name="featParti" id="featParti" type="checkbox" className="form-checkbox" />
                                                        <label htmlFor="featParti" className="font-semibold">
                                                            {t('REGISTRATIONS')}
                                                        </label>
                                                    </div>
                                                    <div className="mt-2">{errors.featParti && <div className="text-white bg-danger py-1 px-2 rounded inline-block">{errors.featParti}</div>}</div>
                                                </div>
                                                <div className={errors.featMatches && touched.featMatches ? 'has-error' : ''}>
                                                    <div className="flex">
                                                        <Field name="featMatches" id="featMatches" type="checkbox" className="form-checkbox" />
                                                        <label htmlFor="participants" className="font-semibold">
                                                            {t('MATCHES')}
                                                        </label>
                                                    </div>
                                                    <div className="mt-2">{errors.featMatches && <div className="text-white bg-danger py-1 px-2 rounded inline-block">{errors.featMatches}</div>}</div>
                                                </div>
                                                <div className={errors.featScores && touched.featScores ? 'has-error' : ''}>
                                                    <div className="flex">
                                                        <Field name="featScores" id="featScores" type="checkbox" className="form-checkbox" />
                                                        <label htmlFor="participants" className="font-semibold">
                                                            {t('STATS')}
                                                        </label>
                                                    </div>
                                                    <div className="mt-2">{errors.featScores && <div className="text-white bg-danger py-1 px-2 rounded inline-block">{errors.featScores}</div>}</div>
                                                </div>
                                            </div>
                                            <div className="flex gap-1 flex-col mt-3">
                                                <h2 className="font-semibold text-primary mb-3 text-base">Participant Information</h2>
                                                <div className={errors.ageAgg && touched.ageAgg ? 'has-error' : ''}>
                                                    <div className="flex">
                                                        <Field name="ageAgg" id="ageAgg" type="checkbox" className="form-checkbox" />
                                                        <label htmlFor="ageAgg" className="font-semibold">
                                                            {t('COLLECT_AGE')}
                                                        </label>
                                                    </div>
                                                    <div className="mt-2">{errors.ageAgg && <div className="text-white bg-danger py-1 px-2 rounded inline-block">{errors.ageAgg}</div>}</div>
                                                </div>
                                                <div className={errors.genderAgg && touched.genderAgg ? 'has-error' : ''}>
                                                    <div className="flex">
                                                        <Field name="genderAgg" id="genderAgg" type="checkbox" className="form-checkbox" />
                                                        <label htmlFor="participants" className="font-semibold">
                                                            {t('COLLECT_GENDER')}
                                                        </label>
                                                    </div>
                                                    <div className="mt-2">{errors.genderAgg && <div className="text-white bg-danger py-1 px-2 rounded inline-block">{errors.genderAgg}</div>}</div>
                                                </div>
                                                <div className={errors.classAgg && touched.classAgg ? 'has-error' : ''}>
                                                    <div className="flex">
                                                        <Field name="classAgg" id="classAgg" type="checkbox" className="form-checkbox" />
                                                        <label htmlFor="participants" className="font-semibold">
                                                            {t('ENABLE_CLASSIFICATIONS')}
                                                        </label>
                                                    </div>
                                                    <div className="mt-2">{errors.classAgg && <div className="text-white bg-danger py-1 px-2 rounded inline-block">{errors.classAgg}</div>}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <h2 className="font-semibold text-primary mb-3 text-base" id="division-group">
                                                {t('ENABLE_DIVISIONS')} *
                                            </h2>
                                            {/* Features */}
                                            <div role="group" aria-labelledby="division-group" className={`flex gap-3 flex-wrap ${errors.divisions && touched.divisions ? 'has-error' : ''}`}>
                                                {Boolean(selectedClub.clubDivisions?.length) ? <>
                                                    {
                                                        edit ?
                                                            event?.divisions?.map((div) => <div className="flex" key={div}>
                                                                <Field name="divisions" id="division" type="checkbox" value={div} className="form-checkbox" />
                                                                <label htmlFor="division" className="font-semibold">
                                                                    {div}
                                                                </label>
                                                            </div>)
                                                            : selectedClub.clubDivisions!.map(({ divKey, divName }) => <div className="flex" key={divKey}>
                                                                <Field name="divisions" id="division" type="checkbox" value={divKey} className="form-checkbox" />
                                                                <label htmlFor="division" className="font-semibold">
                                                                    {divName}
                                                                </label>
                                                            </div>)
                                                    }
                                                </> : (getDivisionByRules(values.rules) || ODivisions).map(({ value, label }) => (
                                                    <div className="flex" key={value}>
                                                        <Field name="divisions" id="division" type="checkbox" value={value} className="form-checkbox" />
                                                        <label htmlFor="division" className="font-semibold">
                                                            {label}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                            {
                                                gateways.length > 0 && <>
                                                    <h2 className="font-semibold text-primary my-3 text-base" id="payment-gateways">
                                                        {t('PAYMENT_GATEWAYS')}
                                                    </h2>
                                                    <div role="group" aria-labelledby="payment-gateways" className={`flex gap-3 flex-wrap ${errors.gateway && touched.gateway ? 'has-error' : ''}`}>
                                                        {gateways.map((gateway) => (
                                                            <div className="flex" key={gateway._id}>
                                                                <Field name="gateway" id="gateway" type="checkbox" value={gateway._id} className="form-checkbox" />
                                                                <label htmlFor="gateway" className="font-semibold">
                                                                    {gateway.name}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            }
                                            <h2 className="font-semibold text-primary my-3 text-base" id="limit-entries">
                                                {t('LIMIT_ENTRIES')}
                                            </h2>
                                            <div role="group" aria-labelledby="limit-entries" className={`flex gap-3 flex-wrap ${errors.limits && touched.limits ? 'has-error' : ''}`}>
                                                <div className="flex items-center gap-4">
                                                    <div className="flex items-center gap-1">
                                                        <label htmlFor="limits" className="font-semibold whitespace-nowrap mb-0">
                                                            {t('LIMIT_ENTRIES_AT')}
                                                        </label>
                                                        <Field name="limits" id="limits" type="number" className="form-input w-24" />
                                                    </div>
                                                    <div className="flex items-center">
                                                        <Field name="waitlist" id="waitlist" type="checkbox" className="form-checkbox" />
                                                        <label htmlFor="waitlist" className="font-semibold whitespace-nowrap mb-0">
                                                            {t('ALLOW_WAITLIST')}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </AnimateHeight>

                                {
                                    selectedClub.tumKey && <div className='border border-primary rounded p-3'>
                                        <h2 className="font-semibold text-primary mb-3 text-base">{t('TUM_OPTIONS')}</h2>
                                        {/* Features */}
                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                                            <div className={errors.tax && touched.tax ? 'has-error' : ''}>
                                                <div className="flex flex-col">
                                                    <label htmlFor="tax" className="font-semibold">
                                                        {t('SELECT_APPLICABLE_TAXES')}
                                                    </label>
                                                    <Field as="select" name="tax" id="tax" className="form-select">
                                                        <option key="NA" value="" hidden>
                                                            {t('OPEN_TO_SELECT_MENU')}
                                                        </option>
                                                        {taxItems.map((item, i) => (
                                                            <option key={i} value={item.taxId}>
                                                                {item.taxName} {item.taxRate}%
                                                            </option>
                                                        ))}
                                                    </Field>
                                                </div>
                                                <div className="mt-2">{errors.tax && <div className="text-white bg-danger py-1 px-2 rounded inline-block">{errors.tax}</div>}</div>
                                            </div>
                                            <div className={errors.createOrder && touched.createOrder ? 'has-error' : ''}>
                                                <div className="flex md:mt-9">
                                                    <Field name="createOrder" id="createOrder" type="checkbox" className="form-checkbox" />
                                                    <label htmlFor="createOrder" className="font-semibold">
                                                        {t('CREATE_ORDER_FOR_ENTRIES')}
                                                    </label>
                                                </div>
                                                <div className="mt-2">{errors.createOrder && <div className="text-white bg-danger py-1 px-2 rounded inline-block">{errors.createOrder}</div>}</div>
                                            </div>
                                            {values.createOrder && orderItems.length > 0 && <div className={errors.tumItem && touched.tumItem ? 'has-error' : ''}>
                                                <div className="flex flex-col">
                                                    <label htmlFor="tumItem" className="font-semibold">
                                                        {t('SELECT_EXISTING_ITEM')}
                                                    </label>
                                                    <Select
                                                        name="tumItem"
                                                        id="tumItem"
                                                        isSearchable
                                                        options={orderItems.map((item) => ({ value: item.itemId, label: `${item.itemName} - ${item.taxRate}% Tax` }))}
                                                        onChange={(selectedOption) => {
                                                            const selected = orderItems.find((item) => item.itemId === selectedOption?.value);
                                                            setFieldValue('tax', selected?.taxId);
                                                            setFieldValue('tumItem', selected);
                                                            setFieldValue('fee', `$${selected?.price}`);
                                                        }}
                                                        defaultValue={values.tumItem ? { value: values.tumItem.itemId, label: `${values.tumItem.itemName} - ${values.tumItem.taxRate}% Tax` } : null}
                                                        onBlur={() => values.tumItem && setFieldTouched('tumItem')}
                                                        placeholder={t('SELECT_EXISTING_ITEM')}
                                                        isClearable
                                                        classNamePrefix="react-select"
                                                        className={`react-select ${errors.tumItem && touched.tumItem ? 'border-danger' : ''}`}
                                                    />
                                                </div>
                                                <div className="mt-2">{errors.tax && <div className="text-white bg-danger py-1 px-2 rounded inline-block">{errors.tax}</div>}</div>
                                            </div>}
                                            <div className='flex flex-col'>
                                                <div className={errors.nonMemberNotification && touched.nonMemberNotification ? 'has-error' : ''}>
                                                    <div className="flex">
                                                        <Field name="nonMemberNotification" id="nonMemberNotification" type="checkbox" className="form-checkbox" />
                                                        <label htmlFor="nonMemberNotification" className="font-semibold">
                                                            {t('NOTIFY_NON_MEMBERS')}
                                                        </label>
                                                    </div>
                                                    <div className="mt-2">{errors.nonMemberNotification && <div className="text-white bg-danger py-1 px-2 rounded inline-block">{errors.nonMemberNotification}</div>}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="row col-span-3">
                                    {/* Donation Option */}
                                    {gateways?.some(each => each.type === "STRIPE" && values.gateway?.includes(each._id)) && <div className={errors.donation && touched.donation ? 'has-error' : ''}>
                                        <div className="flex mb-2">
                                            <Field name="donation" id="donation" type="checkbox" className="form-checkbox" />
                                            <label htmlFor="donation" className="font-semibold">
                                                {t('ALLOW_TS_COLLECT_DONATIONS')}
                                            </label>
                                        </div>
                                    </div>}
                                    {/* Visibility */}
                                    <div className={errors.hidden && touched.hidden ? 'has-error' : ''}>
                                        <div className="flex">
                                            <Field name="hidden" id="hidden" type="checkbox" className="form-checkbox" />
                                            <label htmlFor="hidden" className="font-semibold">
                                                {t('HIDE_MY_EVENT')}
                                            </label>
                                        </div>
                                        <div className="mt-2">{errors.hidden && <div className="text-white bg-danger py-1 px-2 rounded inline-block">{errors.hidden}</div>}</div>
                                    </div>

                                    {!edit && (
                                        <div className={errors.agree && touched.agree ? 'has-error' : ''}>
                                            <div className="flex">
                                                <Field name="agree" id="agree2" type="checkbox" className="form-checkbox" />
                                                <label htmlFor="agree2" className="font-semibold">
                                                    {t("I_AGREE_TO_TERMS")}
                                                </label>
                                            </div>
                                            <div className="mt-2">{errors.agree && touched.agree && <div className="text-white bg-danger py-1 px-2 rounded inline-block">{errors.agree}</div>}</div>
                                        </div>
                                    )}

                                    <div className="flex gap-4 !mt-6">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => {
                                                if (Object.keys(touched).length === 0) {
                                                    setNoChange(true);
                                                } else if (Object.keys(touched).length !== 0 && Object.keys(errors).length === 0) {
                                                    handleSubmit();
                                                } else {
                                                    setTouched(setNestedObjectValues<FormikTouched<any>>(errors, true));
                                                }
                                            }}
                                        >
                                            {t(edit ? 'SAVE' : 'CREATE')}
                                        </button>
                                        {closeModal && (
                                            <button type="button" onClick={() => closeModal(false)}>
                                                {t('CANCEL')}
                                            </button>
                                        )}
                                    </div>
                                    {edit && isNoChange && (
                                        <MessageBanner variant={EVARIANT.WARNING} className="mt-4">
                                            {t('NO_CHANGE')}
                                        </MessageBanner>
                                    )}
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default CreateEvent;
